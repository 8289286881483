export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    const { onAuthStateChanged, auth } = useAuth()
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (!user && to.path !== '/kirjaudu') {
          resolve(navigateTo('/kirjaudu'))
        }
        else {
          resolve()
        }
      })
    })
  }
})
